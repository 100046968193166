import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';
import CategoryList from '../components/category-list';

export default ({data}) => (
    <Layout>
        <div className="columns is-multiline">
            <div className="column">
                {data.home.edges.map(({ node }) => (
                    <MDXRenderer>{node.body}</MDXRenderer>
                ))}
            </div>
            <div className="column">
                <article className="content">
                    <h1 className="title is-4">Welcome</h1>
                    <p>This is my home page, I hope you enjoy your visit and have a great day!</p>

                    <CategoryList />

                </article>
            </div>
        </div>
    </Layout>
);

export const query = graphql`
query {
  home: allMdx(filter: {frontmatter: {home: {eq: true}}}) {
    edges {
      node {
        id,
        body
      }
    }
  }
  categories: allMdx {
    distinct(field: frontmatter___category)
  }  
  }
`
